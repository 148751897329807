import React from "react";
import { SEO, PageLayout, Background, FoodMenu } from "@bluefin/components";
import { Header, Grid, List } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class ServicesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"menu-hero"}>
              <Background.Color width={16} color={"white"}>
                <Header as={"h1"}>Our Services</Header>
              </Background.Color>
              <Background.Image
                width={16}
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              />
            </Background>
          }
        >
          <Grid
            stackable={true}
            verticalAlign={"middle"}
            textAlign={"left"}
            centered={true}
            column={"2"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <h1>Cities/Towns served</h1>
            </Grid.Column>
            <Grid.Row
              stretched={true}
              stackable={true}
              verticalAlign={"middle"}
              textAlign={"center"}
            >
              <Grid.Column width={4} textAlign={"center"}>
                <List
                  items={[
                    "Arlington",
                    "Beverly",
                    "Boston",
                    "Brookline",
                    "Burlington",
                    "Cambridge",
                    "Chelsea",
                    "Danvers",
                    "Everett",
                    "Gloucester",
                    "Hamilton",
                    "Lexington",
                    "Lynn",
                    "Lynnfield",
                    "Malden",
                    "Marblehead",
                    "Medford",
                  ]}
                  bulleted={false}
                />
              </Grid.Column>
              <Grid.Column width={4} textAlign={"center"}>
                <List
                  items={[
                    "Melrose",
                    "Middleton",
                    "North Reading",
                    "Peabody",
                    "Reading",
                    "Revere",
                    "Salem",
                    "Saugus",
                    "Somerville",
                    "Stoneham",
                    "Swampscott",
                    "Topsfield",
                    "Wakefield",
                    "Watertown",
                    "Wilmington",
                    "Winchester",
                    "Winthrop",
                    "Woburn",
                  ]}
                  bulleted={false}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid
            stackable={true}
            className={"menu-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={"16"} textAlign={"left"}>
              <FoodMenu
                header={""}
                showCategoryDropdown={false}
                showMenuLikes={false}
                centerNavigation={true}
                itemDisplayVariant={"HorizontalCard"}
                categoryDisplayVariant={"HorizontalCardRow"}
                menu={{
                  schedules: allFishermanBusinessMenuSchedule.nodes,
                  categories: allFishermanBusinessMenuCategory.nodes,
                  items: allFishermanBusinessMenuItem.nodes,
                }}
                business={{
                  id: fishermanBusiness._id,
                  type: fishermanBusiness.type,
                }}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Services" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessMenuSchedule {
      nodes {
        availableDays
        categories
        _id
        name
      }
    }
    allFishermanBusinessMenuCategory {
      nodes {
        items
        description
        name
        _id
      }
    }
    allFishermanBusinessMenuItem {
      nodes {
        modifierSets
        description
        _id
        name
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        annotations {
          likeCount
        }
        visible
        available
      }
    }
  }
`;
